import React,{useEffect,useState,useRef} from 'react'
import axios from 'axios'
import img from "../logo2.png"
import { format } from 'date-fns';
import Auth from '../Auth';
import { auth } from '../helpers/auth'
import { v4 as uuidv4 } from 'uuid';
import { updateFunctionDeclaration } from 'typescript';
import ModernDatepicker from 'react-modern-datepicker';
function Register({rg}) {
    const [data, setData] = useState();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [dob, setdob] = useState(new Date());
    const [password2, setpassword2] = useState("");
    const [err, seterr] = useState(false);
   const [fn,setfn]=useState("")
   const [ln,setln]=useState("")
   const zipref=useRef(null)
  const cityref=useRef(null)
  const stateref=useRef(null)
   const handleChange=(date)=>{
    setdob(date)
    console.log(date)
  } 
      const addref=useRef(null)
    useEffect(() => {
        window.localStorage.removeItem("auth")
        
        
      }, []);
      
  return (
    <div>
        
      <form className='form-container' onSubmit={(e)=>{
          e.preventDefault()

          const newpackage = {
            status:"unverified",
            id: uuidv4(),
      firstName: fn,
      lastName: ln,
      email: email,
      password: password,
      amount: 0,
      address:addref.current.value,
      dateregistered: format(new Date(),"dd/MM/yyyy"),
      verified: "false",
      averageprofit: 0,
      amountofplans: 0,
      dob:dob,
      zip:zipref.current.value,
state:stateref.current.value,
city:cityref.current.value,
      notifications: [
        {
          id: 1,
          "message": "Welcome to mifintruistInvestments,start earning by simply picking a plan on the Plans page"
        },
        {
            id: 2,
            "message": "Become a Verified Member by picking any plan to get full access to this dashboard,"
          }
      ],
      withdrawals: [
       
      ],
      transactions: [
       
      ],
      
      wallet: ""
    }
  if(password!=password2){
      alert("passwords dont match !!!")
  }
            
          
      else {
          
        
       auth(email,password, newpackage)
             .then(response => {alert("User registered successfully")
              Auth.authenticate()
              window.localStorage.setItem("auth",true)
              window.localStorage.setItem("userid",response.data.name)
              console.log(response);

              window.location.href="/dashboard";
        })
              }

            }
      }>
     
<h3>REGISTER</h3>
<div className="form-group">
    <div className="flx"></div>
    <label className="formtxt">First Name  <span>*</span></label>  
    <input type="text" value={fn} required onChange={(e)=>{
setfn(e.target.value)

    }} className="form-control" placeholder="Enter First Name" />
</div>
<div className="form-group">
<div className="flx"></div>
<label className="formtxt">Last Name  <span>*</span></label>  
    <input type="text" value={ln} required onChange={(e)=>{
setln(e.target.value)

    }} className="form-control" placeholder="Enter Last Name" />
</div>
<div className="form-group">
<label className="formtxt">Email  <span>*</span></label>  
    <input type="email" value={email} required onChange={(e)=>{
setemail(e.target.value)

    }} className="form-control" placeholder="Enter email" />
</div>
<div className="form-group">
    <label className="formtxt">Address  <span>*</span></label>  
    <textarea required ref={addref} className="form-control" rows="1" cols='30' placeholder="Enter Full Address" ></textarea>
</div>

<div className="addflex"> <div>
    <p className="formtxt"> City <span>*</span></p>
    <input type="text" ref={cityref} required/>
    </div>
    <div>
    <p className="formtxt"> State <span>*</span></p>
    <input type="text" ref={stateref} required/>
    </div>
    <div>
    <p className="formtxt"> Zip Code  <span>*</span></p>
    <input type="text"  ref={zipref} required/>
    </div>  </div>

<div className="form-group">
<label className="formtxt">D.O.B  <span>*</span></label>  
<div>
<ModernDatepicker
				date={dob}
				format={'DD-MM-YYYY'}
				showBorder
				onChange={date => handleChange(date)}
				placeholder={'Select a date'}
			/></div>
</div>
   

<div className="form-group">
<label className="formtxt">Password <span>*</span></label>  
    <input type="password" value={password} required onChange={(e)=>{
setpassword(e.target.value)

    }}className="form-control" placeholder="Enter password" />
</div>
<div className="form-group">
<label className="formtxt">Confirm Password <span>*</span></label>  
    <input type="password" value={password2} required onChange={(e)=>{
setpassword2(e.target.value)

    }}className="form-control" placeholder="Enter password" />
</div>


<div>{err&&<p style={{color:"red"}}> There was an error!!! Please try Again</p>}</div>
<button type="submit" className="btn btn-dark btn-lg btn-block">Register</button>
<button onClick={()=>{rg()}} className="btn cancel">Close</button>

<span style={{textAlign:'center'}} >
    Already have an Account? The click the login button
</span>
<span style={{textAlign:'center',fontSize:'8px'}} >
    By registering you automatically agree to out terms & services and privacy policy
</span>

<footer style={{textAlign:'center',fontSize:'8px'}}>&copy; Copyright 2014 mifintruist.com</footer>
</form>


    </div>
  )
}
export default Register
