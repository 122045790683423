import React,{useState,useEffect} from 'react'
import Navbar from '../components/Navbar'
import {Row,Col,Card,CardBody,CardText,CardTitle,Button,
    Label,Form,FormGroup,Input,Alert} from "reactstrap"
    import Rodal from 'rodal'
    import 'rodal/lib/rodal.css';
    import QRCode from "react-qr-code";
    import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from 'axios';
import { ref } from '../config/constants';
import { format } from 'date-fns'
function Plans() {
    const [showmodal,setshow]=useState(false)
    const [plan,setplan]=useState("")
    const [wallet,setwalet]=useState("")
    const [data, setData] = useState();
    const[x,setx]=useState()
    const addtr=()=>{
        

    }

  useEffect(() => {
      const x= window.localStorage.getItem("userid")
      const fetchData2 = async () => {
       ref.child(`walletaddress`).on('value', (snapshot) => {
        console.log();
        setwalet(snapshot.val());
      }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
      }); 
          
  
       
        console.log(wallet)
      };
    const fetchData = async () => {
      ref.child(`users/users/${x}`).on('value', (snapshot) => {
        console.log();
        setData(snapshot.val());
      }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
      }); 
      
      console.log(data)
    };

    fetchData();
    fetchData2();
    setx(x)
  }, []);
  return (<>
 
<Navbar/>
<Rodal visible={showmodal} width={500} height={800} onClose={()=>{
     setshow(false)
 }}>
          <div style={{display:'flex',flexDirection:'column',alignItems:"center",justifyContent:'center'}}><Alert>
    <h4 className="alert-heading">
      Listening For Payment to this Address for {plan} PLAN!!
    </h4>
     <hr />
    <p className="mb-0">
      Click Proceed if Payment has been made</p>
  </Alert>
          <div className="lds-ripple"><div></div><div></div></div>
          <div style={{margin:"auto"}}><QRCode value={wallet} size={250} /></div></div>
          <div style={{display:'flex',justifyContent:'center',gap:'2px'}}>
<p>{wallet}</p>
<br/>
<CopyToClipboard text={wallet}
          onCopy={() => {}}>
          <button>Copy</button>
        </CopyToClipboard>

          </div>
         
          <Row>
              <Col sm="6"> <Card><Button onClick={()=>{setshow(false)}}  color='danger'> Cancel</Button></Card></Col>
              <Col sm="6"> <Card><Button onClick={()=>{
            const cars = {
                id:parseInt(1 + Math.random() * (1000000 - 1)),
                plan:plan,
                date:format(new Date(),"dd/MM/yyyy"),
                status:"pending",
                type:"purchase"
    
            }
    const trucks = data.transactions;
    
    // Method 1: Concat
    const combined1 = [].concat(cars, trucks);
    console.log(combined1)
            const Transactiontopatch={transactions:combined1}
            ref.child(`users/users/${x}/transactions`).push(cars)
          .then(()=>{
                alert("Transaction currently pending confirmation")
                
                    }).catch((error) => alert( error.message))
              
    
            setshow(false)
            }}color='success'> Proceed</Button></Card></Col>
          </Row>
        </Rodal>
    <div className='plans'>

      <p style={{textAlign:"center"}}>MifintruistInvestments offer a diverse and splendid investment portfolio opportunity to those looking to invest in our firm. We deliver investors modern ways of increasing the value of assets dedicated to our company through united states treasury bonds, ensuring that we guarantee investors safe returns and reliable investment portfolios. Mifintruist uses sophisticated blockchain technology to ensure security and investors' confidence, making investment easy and straightforward for on-looking and potential investors. We base our ideology on client satisfaction because we aim to exceed your expectations and go to stellar lengths to provide you with a sound investment environment. The modern company must be data-driven, innovative, and able to scale and respond to market dynamics rapidly. We have perfected this and therefore created a platform to ensure your investment goals are reached; in addition, we have made our investment portfolio easy to navigate and also have a group of team who will be of assistance when needed.


</p>
<h4>Tailored Investment Plans :</h4>
    <Row>
  <Col sm="4">
    <Card body
    inverse
    style={{
     
      borderColor: '#060b26'

    }} className="text-center bcbg">
      <CardTitle tag="h3" style={{color:"greenyellow",zIndex:'3'}}>
        DeFi INVESTMENT
      </CardTitle>
      <p style={{zIndex:'3'}}>PROFITS: 3.3% Daily(less than $7500) </p>
      <p style={{zIndex:'3'}}>PROFITS: 4.7% Daily(greater than $7500) </p>
      <p style={{zIndex:'3'}}>BASE DEPOSIT: $1000</p>
      <p style={{zIndex:'3'}}>MAXDEPOSIT: UNBOUNDED</p>
    
      <h6 style={{zIndex:'3'}}>FASTEST WITHDRAWAL PROCESS</h6>
      <Button style={{zIndex:'3'}} color='warning' onClick={()=>{
          
          setplan("DeFi INV")
          setshow(true)}}>ADD TO PORTFOLIO</Button>
    </Card>
  </Col>
  <Col sm="4">
    <Card body
    inverse
    style={{
      backgroundColor: '#060b26',
      borderColor: '#060b26'
    }} className="text-center gdbg">
      <CardTitle tag="h3" style={{color:"greenyellow",zIndex:"3"}}>
       GOLD & PETROLEUM PRODUCTS
      </CardTitle>
      <p style={{zIndex:'3'}}> </p>
      <p style={{zIndex:'3'}}> </p>
      <p style={{zIndex:'3'}}> </p>
      <p style={{zIndex:'3'}}> </p>
      <p style={{zIndex:'3'}}> </p>
      <h6 style={{zIndex:'3'}}>FASTEST WITHDRAWAL PROCESS</h6>
      <Button style={{zIndex:'3'}}onClick={()=>{
          
          setplan("GOLD & PETROLEUM")
          setshow(true)}} color='warning'>ADD TO PORTFOLIO</Button>
    </Card>
  </Col>
  <Col sm="4">
    <Card body
    inverse
    style={{
      backgroundColor: '#060b26',
      borderColor: '#060b26'
    }} className="text-center rebg">
      <CardTitle tag="h3" style={{color:"greenyellow",zIndex:'3'}}>
        REAL ESTATE PLAN
      </CardTitle>
      <p  style={{zIndex:'3'}}> </p>
      <p  style={{zIndex:'3'}}> </p>
      <p  style={{zIndex:'3'}}> </p>
      <p  style={{zIndex:'3'}}> </p>
      <p  style={{zIndex:'3'}}> </p>
      
      <h6  style={{zIndex:'3'}}>FASTEST WITHDRAWAL PROCESS</h6>
     <Button style={{zIndex:'3'}} onClick={()=>{
          
          setplan("REAL ESTATE")
          setshow(true)}}color='warning'>ADD TO PORTFOLIO</Button>
    </Card>
  </Col>
</Row>
<br/>

 
    </div>
    </>
  )
}

export default Plans
