import * as firebase from 'firebase';

  const firebaseConfig = {
    apiKey: "AIzaSyBnm_8GlENeWiSDKoJWIHBwT2B6qmB0pp8",
    authDomain: "enrollment-a5de6.firebaseapp.com",
    databaseURL: "https://enrollment-a5de6-default-rtdb.firebaseio.com",
    projectId: "enrollment-a5de6",
    storageBucket: "enrollment-a5de6.appspot.com",
    messagingSenderId: "235334460945",
    appId: "1:235334460945:web:8e5ed244e1db9c6f8eb3b0",
    measurementId: "G-DS43K3L789"
  };
  firebase.initializeApp(firebaseConfig)
  
  export const ref = firebase.database().ref()
  
  export const firebaseAuth = firebase.auth