import { ref, firebaseAuth } from '../config/constants'
import Auth from '../Auth'
export function auth (email, pw,np) {
  return firebaseAuth().createUserWithEmailAndPassword(email, pw)
    .then(user=>saveUser(user,np))
    .catch((error) => console.log('Oops', error))
}

export function logout() {
  return firebaseAuth().signOut()
}

export function login (email,pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw)
}

export function loginOauth(oauthId) {

  switch(oauthId) {
    case 'google':
      var provider = new firebaseAuth.GoogleAuthProvider();
      loginPopup(provider);
      break;
    case 'facebook':
      provider = new firebaseAuth.FacebookAuthProvider();
      loginPopup(provider);
      console.log('facebook')
      break;
    case 'twitter':
      provider = new firebaseAuth.TwitterAuthProvider();
      loginPopup(provider);
      break;
  }
}

export function loginPopup(provider){
  return firebaseAuth().signInWithPopup(provider).then(function(result) {
    console.log('logged in')
  }).catch(function(error) {
    console.log(error)
  });    
}

export function saveUser(user,np) {
    const npack={...{uid:user.uid},...np}
  return ref.child(`users/users/${user.uid}`)
    .set(npack) 
    .then(() => {alert("User registered successfully")
    Auth.authenticate()
    window.localStorage.setItem("auth",true)
    window.localStorage.setItem("userid",user.uid)


    window.location.href="/dashboard";
})
}