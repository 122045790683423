import React ,{useEffect,useState} from 'react'
import Navbar from '../components/Navbar'
import {Table,tr,td,thead,tbody,th} from 'reactstrap';
import axios from 'axios';
import { ref } from '../config/constants';
function Transactions() {
    const [data, setData] = useState();
    const[x,setx]=useState()
    useEffect(() => {
        const x=window.localStorage.getItem("userid")
        const fetchData = async () => {
          ref.child(`users/users/${x}`).on('value', (snapshot) => {
            console.log();
            setData(snapshot.val());
          }, (errorObject) => {
            console.log('The read failed: ' + errorObject.name);
          }); 
         
         
        };
      
        fetchData();
        setx(x)
      }, []);
      
      
  return (
      <>
      <Navbar/>
    <div className='transactions'>
      <h4>Recent Transactions :</h4>
      <br/>
      <Table
  dark
>
  <thead>
    <tr>
      <th>
        #
      </th>
      <th>
        PLAN
      </th>
      <th>
       TYPE
      </th>
      <th>
       DATE
      </th>
      <th>
       STATUS
      </th>
    </tr>
  </thead>
  <tbody>
  {!!data&&!!data.transactions ? (
        <>{Object.keys(data.transactions).reverse().map((key,ind)=>{
          return(  <tr>
            <th scope="row">
              {data.transactions[key].id}
            </th>
            <td>
             {data.transactions[key].plan}
            </td>
            <td>
             {data.transactions[key].type}
            </td>
            <td>
             {data.transactions[key].date}
            </td>
            <td>
             {data.transactions[key].status}
            </td>
          </tr>)
        })}
         
        </>
      ) : (
        <><p>You have no Transactions yet</p></>
        )}
    
    </tbody>
    </Table>

    </div>
    </>
  )
}

export default Transactions
